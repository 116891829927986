import { CmykColor, ColorGroup, Colors, PredefinedTexts, RgbColor, ColorTag } from './../../models/color.model';

const primary: ColorGroup = {
  name: 'Main Colors',
  text: {
    predefinedText: PredefinedTexts.Primary
  },
  colors: [
    {
      name: 'Terracotta Orange',
      color: {
        hex: '#E6723D',
        rgb: new RgbColor(230, 114, 61),
        cmyk: new CmykColor(5, 65, 80, 0)
      },
      variants: [
        { hex: '#F6D2C2', rgb: new RgbColor(246, 210, 194),  invertedText: true },
      ],
      tags: [
        ColorTag.Icon, ColorTag.Illustration, ColorTag.Cta, ColorTag.Link
      ]
    },
    {
      name: 'Dusty Gray',
      color: {
        hex: '#97958B',
        rgb: new RgbColor(151, 149, 139),
        cmyk: new CmykColor(31, 25, 33, 0)
      },
      variants: [
        { hex: '#B5B1A5', rgb: new RgbColor(181, 177, 165), },
      ],
      tags: [
        ColorTag.Illustration, ColorTag.Background
      ]
    },
    {
      name: 'Midnight Black',
      color: {
        hex: '#1A1C1B',
        rgb: new RgbColor(26, 28, 27),
        cmyk: new CmykColor(0, 0, 0, 100),
      },
      tags: [
        ColorTag.Heading, ColorTag.Paragraph, ColorTag.Cta, ColorTag.Background, ColorTag.PhotoFilter, ColorTag.Illustration, ColorTag.Icon
      ]
    }
  ]
};

const secondary: ColorGroup = {
  name: 'Support Colors',
  text: {
    predefinedText: PredefinedTexts.Secondary
  },
  colors: [
    {
      name: 'Crimson Red',
      color: {
        hex: '#C60C30',
        rgb: new RgbColor(198, 12, 48),
        cmyk: new CmykColor(15, 100, 77, 5)
      },
      tags: [
        ColorTag.Cta
      ]
    },
    {
      name: 'Royal Blue',
      color: {
        hex: '#4A5CE2',
        rgb: new RgbColor(74, 92, 226),
        cmyk: new CmykColor(82, 67, 0, 0)
      },
      tags: [
        ColorTag.Link
      ]
    },
    {
      name: 'Lime Green',
      color: {
        hex: '#8ED900',
        rgb: new RgbColor(142, 217, 0),
        cmyk: new CmykColor(51, 0, 100, 0)
      },
      variants: [
        { hex: '#40632E', rgb: new RgbColor(64, 99, 46),},
        { hex: '#D8E9BF', rgb: new RgbColor(216, 233, 191),  cmyk: new CmykColor(16, 0, 31, 0),  invertedText: true },
      ],
      tags: [
        ColorTag.Link, ColorTag.Illustration
      ]
    }
  ]
};

const gradients: ColorGroup = {
  name: 'Gradients',
  colors: [
   
  ]
};

const bw: ColorGroup = {
  name: 'Greyscale',
  text: {
    predefinedText: PredefinedTexts.BlackAndWhite
  },
  colors: [
    {
      name: 'Pearl White',
      color: {
        hex: '#F5F4F2',
        rgb: new RgbColor(245, 244, 242),
        cmyk: new CmykColor(8, 7, 8, 0),
        invertedText: true
      },
      tags: [
        ColorTag.Background, ColorTag.Illustration, ColorTag.Icon
      ]
    },
    {
      name: 'Dove Grey',
      color: {
        hex: '#EBE9E5',
        rgb: new RgbColor(235, 233, 229),
        invertedText: true
      },
      tags: [
        ColorTag.Background
      ]
    }
  ]
};

export const DandomainColors: Colors = {
  updated: new Date(2024, 3, 23),
  package: {
    content: [
    ],
  },
  groups: [primary, secondary, gradients, bw],
  cmykExample: { hex: '#E6723D', cmykAsHex: '#E87945' }
};
