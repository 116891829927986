import { animate, animation, style } from '@angular/animations';

export const fadeInAnimation = animation([
  style({
    opacity: '{{ startOpacity }}'
  }),
  animate('{{ duration }}')
], { params: { startOpacity: 0, duration: '300ms' } });

export const fadeOutAnimation = animation([
  style({ opacity: 1 }),
  animate('{{ duration }}', style({ opacity: 0 }))
], { params: { duration: '250ms' } });
