import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { BrandService } from './brand.service';

@Injectable({ providedIn: 'root' })
export class SeoService {

  constructor(private brandService: BrandService, private titleService: Title) { }

  setTitle(title: string) {
    const currentBrand = this.brandService.getCurrentBrand();
    const brand = (currentBrand) ? `- ${currentBrand.name}` : '';
    this.titleService.setTitle(`${title} ${brand} - brandguide.blue`);
  }
}
