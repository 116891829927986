export interface Colors {
  updated: Date;
  package: ColorPackage;
  groups: ColorGroup[];
  cmykExample: CmykExample;
}

interface ColorPackage {
  content: string[];
  downloadUrl?: string;
}

interface CmykExample {
  hex: string;
  cmykAsHex: string;
}

export interface ColorGroup {
  name: string;
  colors: Color[];
  text?: ColorText;
}

interface ColorText {
  predefinedText?: PredefinedTexts;
  customText?: string;
}

export interface ColorDef {
  hex: string;
  rgb: RgbColor;
  cmyk?: CmykColor;
  invertedText?: boolean;
}

export interface Color {
  name: string;
  color: ColorDef;
  variants?: ColorDef[];
  tags?: ColorTag[];
  toColor?: ColorDef;
}

export enum PredefinedTexts {
  Primary,
  Secondary,
  Topic,
  Gradients,
  BlackAndWhite,
  Accent,
}

export enum ColorTag {
  Background = 'Background',
  Paragraph = 'Text',
  Cta = 'CTA',
  Logo = 'Logo',
  Icon = 'Icon',
  PhotoFilter = 'Photo filter',
  Heading = 'Heading',
  Illustration = 'Illustration',
  Link = 'Link',
  ImportantInformation = 'Important information',
  Error = 'Error',
  Success = 'Success',
}

export class RgbColor {
  r: number;
  g: number;
  b: number;

  constructor(r: number, g: number, b: number) {
    this.r = r;
    this.g = g;
    this.b = b;
  }
}

export class CmykColor {
  c: number;
  m: number;
  y: number;
  k: number;

  constructor(c: number, m: number, y: number, k: number) {
    this.c = c;
    this.m = m;
    this.y = y;
    this.k = k;
  }
}
