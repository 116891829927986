import { Brand } from './../models/brand.model';
import { SimplyColors } from './colors/simply.colors';
import { SimplyLogos } from './logos/simply.logos';
import { SimplyPersonas } from './personas/simply.personas';
import { SimplyTemplates } from './templates/simply.templates';
import { SimplyTypography } from './typography/simply.typography';

export const SimplyBrand: Brand = {
  name: 'simply.com',
  alias: 'simply',
  displayName: 'Simply',
  colors: SimplyColors,
  logos: SimplyLogos,
  typography: SimplyTypography,
  personas: SimplyPersonas,
  templates: SimplyTemplates
};
