import { Personas } from '../../models/persona.model';

export const curanetPersonas: Personas = {
  updated: new Date(2021, 4, 26),
  package: {
    content: ['PowerPoint presentation file'],
    downloadUrl:
      'https://teambluegroup.sharepoint.com/:p:/r/sites/teamblueDenmark-Marketing/_layouts/15/guestaccess.aspx?e=EGPFL8&share=EWuzCf6S7z5CssEpLIWkvH8BSs063L9_B2tv16Q-rQIfpA',
  },
  area: {
    description: `
    To fokusområder + én fælles Premium til partnere med mange medarbejdere og med behov for stor kapacitet.<br>
    Herefter inddeler vi i, hvem vi henvender os til af medarbejdere i de pågældende virksomheder.`,

    areas: [
      {
        header: 'IT',
        description: `Det tekniske fokusområde, der fokuserer på partnere, som tænder på IT.`,
        colors: {
          primary: '#174b6b',
          secondary: '#01a1d7',
        },
        personas: [
          {
            name: `IT - <br> Consultant`,
            alias: 'itcons',
            icon: 'home.svg',
            targets: [
              {
                title: `Employee(s)`,
                value: `1-4`,
              },
              {
                title: `No. of customers`,
                value: `1-99`,
              },
            ],
            contacts: [
              {
                icon: 'user.svg',
                title: 'Owner',
              },
            ],
            productGroups: [
              {
                products: [
                  {
                    name: `Microsoft 365`,
                    value: ``,
                  },
                  {
                    name: `Veeam backup`,
                    value: ``,
                  },
                  {
                    name: `Domains & DNS`,
                    value: ``,
                  },
                  {
                    name: `Webhotel`,
                    value: ``,
                  },
                ],
              },
              {
                products: [
                  {
                    name: `VPS Servere`,
                    value: ``,
                  },
                ],
              },
              {
                products: [
                  {
                    name: `Hosted.dk`,
                    value: ``,
                  },
                  {
                    name: `Antivirus`,
                    value: ``,
                  },
                  {
                    name: `Enterprise Cloud Server`,
                    value: ``,
                  },
                ],
              },
            ],
          },
          {
            name: `IT - <br>  Company`,
            alias: 'itcomp',
            icon: 'house.svg',
            targets: [
              {
                title: `Employee(s)`,
                value: `5-100`,
              },
              {
                title: `No. of customers`,
                value: `100-499`,
              },
            ],
            contacts: [
              {
                icon: 'server.svg',
                title: 'Technical Employee',
              },
              {
                icon: 'shopping-cart-black-shape.svg',
                title: 'Sales Employee',
              },
            ],
            productGroups: [
              {
                products: [
                  {
                    name: `Domains & DNS`,
                    value: ``,
                  },
                  {
                    name: `Linux Webhotel`,
                    value: ``,
                  },
                  {
                    name: `Mailhotel`,
                    value: ``,
                  },
                  {
                    name: `Veeam Backup`,
                    value: ``,
                  },
                  {
                    name: `Hosted Business Løsning`,
                    value: ``,
                  },
                  {
                    name: `Microsoft 365`,
                    value: ``,
                  },
                ],
              },
              {
                products: [
                  {
                    name: `Azure`,
                    value: ``,
                  },
                  {
                    name: `Virtual Datacenter`,
                    value: ``,
                  },
                  {
                    name: `VPS Servere`,
                    value: ``,
                  },
                  {
                    name: `Veeam Backup VDC/CRP`,
                    value: ``,
                  },
                  {
                    name: `StorageFactory`,
                    value: ``,
                  },
                  {
                    name: `SPLA Licens`,
                    value: ``,
                  },
                  {
                    name: `Antivirus`,
                    value: ``,
                  },
                ],
              },
              {
                products: [
                  {
                    name: `Hosted.dk`,
                    value: ``,
                  },
                  {
                    name: `Enterprise Cloud Server`,
                    value: ``,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        header: `Premium`,
        description: `Et fælles område til partnere med mange medarbejder og med behov for stor kapacitet.`,
        colors: {
          primary: '#2e770d',
          secondary: '#8bc145',
        },
        personas: [
          {
            name: `Premium - <br>  Bureau Hosting "Stor"`,
            alias: 'buhs',
            icon: 'building.svg',
            targets: [
              {
                title: `Employee(s)`,
                value: `+25`,
              },
              {
                title: `No. of customers`,
                value: `500-4999`,
              },
            ],
            contacts: [
              {
                icon: 'server.svg',
                title: 'Technical Employee',
              },
              {
                icon: 'shopping-cart-black-shape.svg',
                title: 'Sales Employee',
              },
            ],
            productGroups: [
              {
                products: [
                  {
                    name: `Domains & DNS`,
                    value: ``,
                  },
                  {
                    name: `Hosted Business Løsning`,
                    value: ``,
                  },
                  {
                    name: `Webhotel + Pro`,
                    value: ``,
                  },
                  {
                    name: `Mailhotel`,
                    value: ``,
                  },
                  {
                    name: `SSL Certificate Premium`,
                    value: ``,
                  },
                  {
                    name: `Microsoft 365`,
                    value: ``,
                  },
                  {
                    name: `Veeam Backup`,
                    value: ``,
                  },
                ],
              },
              {
                products: [
                  {
                    name: `Azure`,
                    value: ``,
                  },
                  {
                    name: `Virtual Datacenter`,
                    value: ``,
                  },
                  {
                    name: `VPS Servere`,
                    value: ``,
                  },
                  {
                    name: `Veeam Backup VDC/CRP`,
                    value: ``,
                  },
                  {
                    name: `StorageFactory`,
                    value: ``,
                  },
                  {
                    name: `SPLA Licens`,
                    value: ``,
                  },
                  {
                    name: `Antivirus`,
                    value: ``,
                  },
                ],
              },
              {
                products: [
                  {
                    name: `Enterprise Cloud Server`,
                    value: ``,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        header: `Bureau`,
        description: `Området der fokuserer på partnere, som tænker; "det skal bare virke!"`,
        colors: {
          primary: '#6d2140',
          secondary: '#a53260',
        },
        personas: [
          {
            name: `Bureau - <br>  E-commerce`,
            alias: 'buecom',
            icon: 'house.svg',
            targets: [
              {
                title: `Employee(s)`,
                value: `2-24`,
              },
              {
                title: `No. of customers`,
                value: `50-1000`,
              },
            ],
            contacts: [
              {
                icon: 'assistance.svg',
                title: 'Web Designer/Consultant',
              },
            ],
            productGroups: [
              {
                products: [
                  {
                    name: `Domains & DNS`,
                    value: ``,
                  },
                  {
                    name: `Hosted Shop Advanced`,
                    value: ``,
                  },
                  {
                    name: `Webhotel`,
                    value: ``,
                  },
                  {
                    name: `Mailhotel`,
                    value: ``,
                  },
                  {
                    name: `SSL Certificate Premium`,
                    value: ``,
                  },
                ],
              },
            ],
          },
          {
            name: `Bureau - <br>  Hosting`,
            alias: 'buhost',
            icon: 'house.svg',
            targets: [
              {
                title: `Employee(s)`,
                value: `2-24`,
              },
              {
                title: `No. of customers`,
                value: `50-1000`,
              },
            ],
            contacts: [
              {
                icon: 'assistance.svg',
                title: 'Web Designer/Consultant',
              },
            ],
            productGroups: [
              {
                products: [
                  {
                    name: `Domains & DNS`,
                    value: ``,
                  },
                  {
                    name: `Hosted Business Løsning`,
                    value: ``,
                  },
                  {
                    name: `Webhotel + Pro`,
                    value: ``,
                  },
                  {
                    name: `Mailhotel`,
                    value: ``,
                  },
                  {
                    name: `SSL Certificate Premium`,
                    value: ``,
                  },
                  {
                    name: `Microsoft 365`,
                    value: ``,
                  },
                  {
                    name: `Veeam Backup`,
                    value: ``,
                  },
                ],
              },
              {
                products: [
                  {
                    name: `VPS Servere`,
                    value: ``,
                  },
                  {
                    name: `Cloud Server`,
                    value: ``,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
