import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { CuranetBrand } from './../brands/curanet.brand';
import { DandomainBrand } from './../brands/dandomain.brand';
import { ScannetBrand } from './../brands/scannet.brand';
import { SimplyBrand } from './../brands/simply.brand';
import { TeamBlueBrand } from './../brands/teamblue.brand';
import { Brand } from './../models/brand.model';
import { ThemeService } from './theme.service';


@Injectable({
  providedIn: 'root'
})
export class BrandService {

  private brands: Brand[] = [
    SimplyBrand,
    CuranetBrand,
    ScannetBrand,
    DandomainBrand,
    TeamBlueBrand
  ];
  private brand: Brand;
  currentBrand = new BehaviorSubject<Brand>(null);

  constructor(private themeService: ThemeService, private router: Router) { }

  setBrand(brand: string) {
    this.brand = this.getBrand(brand);
    if (this.brand) {
      this.currentBrand.next(this.brand);
      this.themeService.setTheme(brand);
      this.themeService.updateLayoutWithTheme();
    } else {
      this.router.navigate(['404']);
    }
  }

  private getBrand(brandAlias: string) {
    return this.brands.find(b => b.alias === brandAlias);
  }

  getCurrentBrand() {
    return this.brand;
  }

  getBrands() {
    return this.brands;
  }

  getTypography() {
    return this.brand.typography;
  }

  getColors() {
    return this.brand.colors;
  }

  getLogos() {
    return this.brand.logos;
  }

  getPersonas() {
    return this.brand.personas;
  }

  getTemplates() {
    return this.brand.templates;
  }

  getToneOfVoice() {
    return this.brand.toneOfVoice;
  }

  getSocialMedia() {
    return this.brand.socialMedia;
  }

}
