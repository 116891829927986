import { Typography, FontFamily, FontLinkIcon } from './../../models/typography.model';

const aktivgrotesk: FontFamily = {
  displayName: 'Aktiv Grotesk',
  family: 'aktiv-grotesk, sans-serif'
};

export const CuranetTypography: Typography = {
  updated: new Date(2020, 3, 22),
  downloadUrl: '/assets/download/fonts/curanet_fontpack_22042020.zip',
  typeKitId: 'gkp4tpz',
  fonts: [
    { fontFamily: aktivgrotesk, defaultWeight: 400, fontLink: {
      icon: FontLinkIcon.Adobe,
      link: 'https://fonts.adobe.com/fonts/aktiv-grotesk'
    }, fontWeight: [
      { name: 'Light', weight: 300 },
      { name: 'Regular', weight: 400 },
      { name: 'Medium', weight: 500 },
      { name: 'Bold', weight: 700 }
    ]}
  ],
  fontSizes: [
    { header: 'Headings', sizes: [
      { name: 'h1', fontFamily: aktivgrotesk, fontSize: 28, fontWeight: 500 },
      { name: 'h2', fontFamily: aktivgrotesk, fontSize: 24.5, fontWeight: 500 },
      { name: 'h3', fontFamily: aktivgrotesk, fontSize: 21, fontWeight: 500 },
      { name: 'h4', fontFamily: aktivgrotesk, fontSize: 17.5, fontWeight: 500 },
      { name: 'h5', fontFamily: aktivgrotesk, fontSize: 16.1, fontWeight: 500 },
      { name: 'h6', fontFamily: aktivgrotesk, fontSize: 14, fontWeight: 500 },
    ]},
    { header: 'Body text', sizes: [
      { name: 'Body', fontFamily: aktivgrotesk, fontSize: 14, fontWeight: 400, lorem: true }
    ]}
  ]
};
