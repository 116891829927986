import { Typography, FontFamily, FontLinkIcon } from './../../models/typography.model';


const poppins: FontFamily = {
  displayName: 'Poppins',
  family: '"Poppins", cursive'
};

export const DandomainTypography: Typography = {
  updated: new Date(2024, 3, 23),
  googleFonts: ['Poppins:400,500,700'],
  fonts: [
    { fontFamily: poppins, defaultWeight: 400, fontLink: {
      icon: FontLinkIcon.Google,
      link: 'https://fonts.google.com/specimen/Poppins'
    }, fontWeight: [
      { name: 'Regular', weight: 400 },
      { name: 'Medium', weight: 500 },
      { name: 'Semibold', weight: 600 },
    ]}
  ],
  fontSizes: [
    { header: 'Headings', sizes: [
      { name: 'h1', fontFamily: poppins, fontSize: 80, fontWeight: 500 },
      { name: 'h2', fontFamily: poppins, fontSize: 60, fontWeight: 500 },
      { name: 'h3', fontFamily: poppins, fontSize: 36, fontWeight: 500 },
      { name: 'h4', fontFamily: poppins, fontSize: 26, fontWeight: 500 },
      { name: 'h5', fontFamily: poppins, fontSize: 18, fontWeight: 500 },
      { name: 'h6', fontFamily: poppins, fontSize: 16, fontWeight: 500 },
    ]},
    { header: 'Body text', sizes: [
      { name: 'Body', fontFamily: poppins, fontSize: 14, fontWeight: 400, lorem: true }
    ]}
  ]
};
