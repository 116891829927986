import { Logos } from './../../models/logo.model';

export const CuranetLogos: Logos = {
  updated: new Date(2024, 4, 15),
  package: {
    downloadUrl: '/assets/download/logo/curanet_logopackage_15052024.zip',
    background: '/assets/images/brands/curanet/logo/package-background.svg',
    logo: '/assets/images/brands/curanet/logo.svg',
    content: ['Coming soon'],
  },
  variantes: [
    {
      logo: '/assets/images/brands/curanet/logo.svg',
      backgroundColor: '#F5F6FA',
      title: 'Main logo',
      description: 'The main version should be used whenever possible.',
    },
    {
      logo: '/assets/images/brands/curanet/logo/alternative-inverted-logo.svg',
      backgroundColor: '#040F39',
      title: 'Inverted logo',
      description:
        'The inverted version should be used on dark and busy backgrounds.',
    },
    {
      logo: '/assets/images/brands/curanet/inverted-logo.svg',
      backgroundColor: '#01969C',
      title: 'Alternative Inverted logo',
      description:
        'The alternative inverted version should be used, when the logomark wouldn’t work on busy backgrounds.',
    },
  ],
  glyphs:[ {
    title: 'Logomark',
    description: 'Only use this for situations where space is very tight, and where you will be unable to read the wordmark.',
    glyphs: [
      {
        logo: '/assets/images/brands/curanet/logo/glyph.svg',
        backgroundColor: '#F5F6FA',
      },
    ],
  }
],
  spaceing: {
    images: [
      '/assets/images/brands/curanet/logo/spacing-1.svg',
      '/assets/images/brands/curanet/logo/spacing-2.svg'
    ],
    rules: [
      {
        title: 'Spacing',
        description: `To make sure the logo is not going to be placed too close to an edge,
        leave at least one of the letter "c" around the logo as space (safearea).`,
      },
    ],
  },
  minimumSizes: {
    web: { logo: '/assets/images/brands/curanet/logo/minimum-rgb.svg', minimumSize: '100px' },
    print: { logo: '/assets/images/brands/curanet/logo/minimum-cmyk.svg', minimumSize: '25mm' }
  },
  rgbVsCmyk: { rgbLogo: '/assets/images/brands/curanet/logo/logo-rgb.svg', cmykLogo: '/assets/images/brands/curanet/logo/logo-cmyk.svg' },
  misuses: [
    {
      logo: '/assets/images/brands/curanet/logo/misuse/rotate.svg',
      note: `Don’t rotate the logo.`
    }, {
      logo: '/assets/images/brands/curanet/logo/misuse/stretch.svg',
      note: `Don’t stretch the logo.`
    }, {
      logo: '/assets/images/brands/curanet/logo/misuse/full-color.svg',
      note: `Don’t use a different full or gradient color of the entire logo other than white.`
    }, {
      logo: '/assets/images/brands/curanet/logo/misuse/background-color.svg',
      note: `Don’t use the main logo on similar or busy background colors.`,
      backgroundColor: '#01969C'
    }, 
  ],
  some: {
    images: [
      '/assets/images/brands/curanet/logo/some/some-inverted-logo.svg',
      '/assets/images/brands/curanet/logo/some/some-glyph.svg'
    ],
    title: 'Social Media profile image',
    note: `We have provided two different versions of the logo for social medias in order to differentiate the expression of the brand according to the different social media platforms. `
  },
  favIcon: {
    icon: '/assets/images/brands/curanet/logo/favicon.svg',
    note: `The logomark should be used as the favicon.
    `
  }
};
