import { SocialMedia } from "../../models/social-media.model";

export const DandomainSocialMedia: SocialMedia = {
  updated: new Date(2022, 2, 10),
  medias: {
    facebook: {
      content: [
        'Events',
        'Webinar',
        'Blogindlæg',
        'Medarbejderhistorier',
        'Gode råd indenfor branchen'
      ],
      tone: [
        'Formel/uformel',
        'Passionerede',
        'Inviterende',
        'Inddragende'
      ],
      description: `Når det gælder Facebook, så er der fokus på Dandomains community. På Facebook er der allerede oprettet flere grupper, hvor Dandomains community hjælper hinanden med at få succes online. Et succeskriterie for Dandomains Facebookside er derfor, hvis Dandomain kan tale ind i det eksisterende community og skabe interaktion på egen Facebookside. Derfor skal indholdet vinkles ud fra, hvad der binder vores community sammen - nemlig at få succes online, og hvordan Dandomain kan være den hjælpende hånd på denne rejse.`,
      purpose: `At pleje Dandomains community og tiltrække potentielle nye kunder.`,
      target: `Personer, der er samlet omkring en fælles interesse om at få succes online med Dandomain som samarbejdspartner. `,
      link: 'https://www.facebook.com/Dandomain'
    },
    instagram: {
      content: [
        '”Bag om Dandomain” content',
        '“her og nu” content',
        'gode råd, tips og tricks til online succes',
        'Events',
        'Storytelling content'
      ],
      tone: [
        'Autentisk',
        'Passioneret',
        'Inviterende',
        'Inddragende',
      ],
      description: `Når det gælder Instagram er der fokus på det kreative indhold. Instagram er kendt for sit æstetiske og visuelle univers. Indholdet må derfor gerne være i god kvalitet og i de rette formater. Platformen er præget af store og små communities, hvor folk er samlet om en fællesinteresse. Derfor skal indholdet vinkles ud fra, hvad der binder Dandomains community sammen. Hvilken fælles rejse er vi på? En rejse om at få succes online med Dandomain som samarbejdspartner. `,
      purpose: `At pleje vores eksisterende community og opbygge et stærkere community.  `,
      target: `Personer, der er samlet omkring en fælles interesse om at få succes online med Dandomain som samarbejdspartner.`,
      link: 'https://www.instagram.com/dandomain.dk/'
    },
    linkedin: {
      content: [
        '”Ugens partner”',
        'Events',
        'Webinar',
        'Blogindlæg',
        'Nyheder indenfor branchen',
        'Foretningstips',
        'Læring',
        'Resultater',
        'Succeshistorier'
      ],
      tone: [
        'Professionel',
        'Jordnære',
        'Passionerede',
      ],
      description: `Når det gælder LinkedIn, er der fokus på forretning/samarbejdspartnere. Her skal indholdet vinkles ud fra den faglige vinkel.  `,
      purpose: `At netværke med samarbejdspartnere og kunder. Det er med til at positionere Dandomain som en troværdig samarbejdspartner, hvilket kan tiltrække nye kunder og samarbejdspartnere. `,
      target: `Fagpersoner, B2B`,
      link: 'https://www.linkedin.com/company/dandomain-as/'
    },
    tiktok: {
      content: [
        '“Bag om Dandomain” videoer',
        'TikTok trends',
        'challenges',
        'fredagshund'
      ],
      tone: [
        'Uformel',
        'Hurtig',
        'Interagerende',
        'Legende'
      ],
      description: `Når det gælder TikTok er der fokus på det kreative indhold. Her skal indholdet vinkles ud fra en god underholdningsværdi og god storytelling. `,
      purpose: `At skabe kendskab til Dandomain hos den yngre målgruppe. Målet er, at hvis målgruppen senere i livet skal bruge et produkt som Dandomain tilbyder, at de så vil komme i tanke om Dandomain. `,
      target: `Yngre målgruppe og fremtidige iværksættere.`,
      link: 'https://www.tiktok.com/@dandomain'
    },
    youtube: {
      content: [
        'Webinar',
        'Gode råd',
        'Succeshistorier',
        '“How to” videoer'
      ],
      tone: [
        'Frisk',
        'Fængende',
        'Ekspertviden',
        'Passion'
      ],
      description: `Når det gælder YouTube, er der fokus på vidensdeling. Her skal indholdet vinkles ud fra, hvordan at vi kan bidrage med viden til målgruppen.  `,
      purpose: `At ruste vores målgruppe til at få succes med deres online forretning gennem vidensdeling. Dette kan positionere Dandomain, som eksperter indenfor branchen og bidrage til at tiltrække nye kunder og samarbejdspartnere. `,
      target: `Personer, der ønsker at tilegne sig ny viden.`,
      link: 'https://www.youtube.com/dandomain'
    }
  }
};
