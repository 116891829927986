import { CmykColor, ColorGroup, Colors, PredefinedTexts, RgbColor, ColorTag } from './../../models/color.model';

const primary: ColorGroup = {
  name: 'Primary Colors',
  text: {
    predefinedText: PredefinedTexts.Primary
  },
  colors: [
    {
      name: 'C1 CuraGreen',
      color: {
        hex: '#01969c',
        rgb: new RgbColor(1, 150, 156),
        cmyk: new CmykColor(80, 18, 39, 3)
      },
      variants: [
        { hex: '#014b4e', rgb: new RgbColor(1, 75, 78) },
        { hex: '#01696d', rgb: new RgbColor(1, 105, 109) },
        { hex: '#01878c', rgb: new RgbColor(1, 135, 140) },
        { hex: '#67c0c4', rgb: new RgbColor(103, 192, 196) },
        { hex: '#99d5d7', rgb: new RgbColor(153, 213, 215), invertedText: true },
        { hex: '#cceaeb', rgb: new RgbColor(204, 234, 235), invertedText: true },
      ],
      tags: [
        ColorTag.Background, ColorTag.Heading, ColorTag.Illustration, ColorTag.PhotoFilter, ColorTag.Cta, ColorTag.Link, ColorTag.Icon
      ]
    },
    {
      name: 'C2 NetBlue',
      color: {
        hex: '#08173f',
        rgb: new RgbColor(8, 23, 63),
        cmyk: new CmykColor(100, 89, 44, 56)
      },
      variants: [
        { hex: '#06102c', rgb: new RgbColor(6, 16, 44) },
        { hex: '#212f53', rgb: new RgbColor(17, 27, 68) },
        { hex: '#394565', rgb: new RgbColor(29, 39, 78) },
        { hex: '#6b748c', rgb: new RgbColor(54, 63, 98) },
        { hex: '#9ca2b2', rgb: new RgbColor(104, 112, 137) },
        { hex: '#ced1d9', rgb: new RgbColor(154, 159, 177), invertedText: true },
      ],
      tags: [
        ColorTag.Background, ColorTag.Heading, ColorTag.Paragraph, ColorTag.Illustration, ColorTag.PhotoFilter
      ]
    }
  ]
};

const secondary: ColorGroup = {
  name: 'Secondary Colors',
  text: {
    predefinedText: PredefinedTexts.Secondary
  },
  colors: [
    {
      name: 'C3 Contrast Purple',
      color: {
        hex: '#5d6bbc',
        rgb: new RgbColor(93, 107, 188)
      },
      tags: [
        ColorTag.Illustration
      ]
    }
  ]
};

const topic: ColorGroup = {
  name: 'Topic Colors',
  text: {
    predefinedText: PredefinedTexts.Topic
  },
  colors: [
    {
      name: 'C4 Outsourcing Green',
      color: {
        hex: '#8DC63F',
        rgb: new RgbColor(141, 198, 63),
        cmyk: new CmykColor(50, 0, 99, 0)
      },
      tags: [
        ColorTag.Heading, ColorTag.Icon, ColorTag.Illustration, ColorTag.Cta
      ]
    },
    {
      name: 'C5 Webhosting Blue',
      color: {
        hex: '#01A1D7',
        rgb: new RgbColor(1, 161, 215),
        cmyk: new CmykColor(75, 20, 2, 0)
      },
      tags: [
        ColorTag.Heading, ColorTag.Icon, ColorTag.Illustration, ColorTag.Cta
      ]
    },
    {
      name: 'C6 E-mail Orange',
      color: {
        hex: '#CF633E',
        rgb: new RgbColor(207, 99, 62),
        cmyk: new CmykColor(14, 73, 84, 3)
      },
      tags: [
        ColorTag.Heading, ColorTag.Icon, ColorTag.Illustration, ColorTag.Cta
      ]
    },
    {
      name: 'C7 E-commerce Pink',
      color: {
        hex: '#A53275',
        rgb: new RgbColor(165, 50, 117),
        cmyk: new CmykColor(36, 95, 25, 3)
      },
      tags: [
        ColorTag.Heading, ColorTag.Icon, ColorTag.Illustration, ColorTag.Cta
      ]
    },
    {
      name: 'C8 Finance Yellow',
      color: {
        hex: '#DDBD39',
        rgb: new RgbColor(221, 189, 57),
        cmyk: new CmykColor(15, 22, 92, 0)
      },
      tags: [
        ColorTag.Heading, ColorTag.Icon, ColorTag.Illustration, ColorTag.Cta
      ]
    },
    {
      name: 'C9 Security Green​',
      color: {
        hex: '#7BC8A3',
        rgb: new RgbColor(123, 200, 163),
        cmyk: new CmykColor(52, 0, 46, 0)
      },
      tags: [
        ColorTag.Heading, ColorTag.Icon, ColorTag.Illustration, ColorTag.Cta
      ]
    }
  ]
};

const gradients: ColorGroup = {
  name: 'Gradients',
  text: {
    predefinedText: PredefinedTexts.Gradients
  },
  colors: [
    {
      name: 'C201 NetGradient',
      color: {
        hex: '#08173F',
        rgb: new RgbColor(8, 23, 63)
      },
      toColor: {
        hex: '#37405f',
        rgb: new RgbColor(55, 64, 95)
      },
      tags: [
        ColorTag.Background, ColorTag.PhotoFilter
      ]
    }
  ]
};

const bw: ColorGroup = {
  name: 'Greyscale',
  text: {
    predefinedText: PredefinedTexts.BlackAndWhite
  },
  colors: [
    {
      name: 'C10 Midnight Black',
      color: {
        hex: '#1C2536',
        rgb: new RgbColor(28, 37, 54)
      },
      tags: [
        ColorTag.Heading, ColorTag.Paragraph
      ]
    },
    {
      name: 'C11 Silver Night',
      color: {
        hex: '#787f87',
        rgb: new RgbColor(120, 127, 135)
      },
      tags: [
        ColorTag.Paragraph, ColorTag.Icon
      ]
    },
    {
      name: 'C12 Day Light',
      color: {
        hex: '#f7f9fa',
        rgb: new RgbColor(247, 249, 250),
        cmyk: new CmykColor(1, 0, 0, 2),
        invertedText: true
      },
      tags: [
        ColorTag.Background, ColorTag.Heading, ColorTag.Paragraph
      ]
    }
  ]
};

export const CuranetColors: Colors = {
  updated: new Date(2021, 4, 26),
  package: {
    downloadUrl: '/assets/download/color/curanet_colorpackage_22042020.zip',
    content: [
      'Adobe Swatch Exchange file (.ase)',
      'Adobe Photoshop Color file (.aco)'
    ],
  },
  groups: [primary, secondary, topic, gradients, bw],
  cmykExample: { hex: '#01969c', cmykAsHex: '#01969c' }
};
