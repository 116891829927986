import { Templates } from '../../models/template.model';

export const CuranetTemplates: Templates = {
  updated: new Date(2022, 2, 9),
  package: {
    content: ['Download the template you want to use'],
    downloadUrl: '...'
  },
  areas: [
    {
      name: 'Presentations',
      groups: [
        {
          name: 'Powerpoints',
          columns: 2,
          templates: [
            {
              name: 'Powerpoint - Sales Presentation ',
              image: '/assets/images/brands/curanet/templates/curanet-præsentation.jpg',
              downloadUrl: 'https://teambluegroup.sharepoint.com/:p:/s/teamblueDenmark-Marketing/ET1QAUqs4ERDitne_klmI50BzpgCZpQI96iWUQCxh6z8-A?e=wcv5mt',
              externalUrl: true
            },
          ]
        }
      ]
    }
  ]
};
