import { curanetToneOfVoices } from './tone-of-voice/curanet.tone-of-voice';
import { Brand } from './../models/brand.model';
import { CuranetColors } from './colors/curanet.colors';
import { CuranetLogos } from './logos/curanet.logos';
import { curanetPersonas } from './personas/curanet.personas';
import { CuranetTypography } from './typography/curanet.typography';
import { CuranetTemplates } from './templates/curanet.templates';

export const CuranetBrand: Brand = {
  name: 'curanet.dk',
  alias: 'curanet',
  displayName: 'Curanet',
  colors: CuranetColors,
  logos: CuranetLogos,
  typography: CuranetTypography,
  personas: curanetPersonas,
  toneOfVoice: curanetToneOfVoices,
  templates: CuranetTemplates,
};
