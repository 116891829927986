import { animate, query, style, transition, trigger } from '@angular/animations';

export const RouteFade = trigger('routeFade', [
  transition('* <=> *', [
    query(':enter',
      style({
        opacity: 0
      }),
      { optional: true }),
    query(':enter',
      animate('520ms ease',
        style({
          opacity: 1
        })
      ),
      { optional: true }),
  ])
]);
