<h2 mat-dialog-title>Employee login</h2>
<mat-dialog-content>
  <form [formGroup]="form" (ngSubmit)="onLogin()">
    <mat-form-field appearance="outline" color="primary">
      <mat-label>Password</mat-label>
      <input type="password" matInput formControlName="password">
      <mat-error *ngIf="form.get('password').hasError('required') && form.get('password').touched">The password field is required.</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="login-button" mat-flat-button color="primary" (click)="onLogin()">Log in</button>
  <mat-error class="incorrect" *ngIf="form.get('password').valid && form.get('password').value === wrongPassword">The password was incorrect.</mat-error>
</mat-dialog-actions>

