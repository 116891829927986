export interface Typography {
  updated: Date;
  downloadUrl?: string;
  googleFonts?: string[];
  typeKitId?: string;
  customFont?: any;
  fonts: Fonts[];
  fontSizes: FontSizeGroup[];
}

interface Fonts {
  fontFamily: FontFamily;
  fontWeight: FontWeight[];
  defaultWeight: number;
  fontLink?: FontLink;
}

interface FontLink {
  link: string;
  icon: FontLinkIcon;
}

export enum FontLinkIcon {
  Google,
  Adobe,
  Custom
}

interface FontWeight {
  name?: string;
  weight: number;
}

interface FontSizeGroup {
  header: string;
  sizes: FontSize[];
}

interface FontSize {
  name: string;
  fontSize: number;
  fontFamily: FontFamily;
  fontWeight: number;
  lorem?: boolean;
}

export interface FontFamily {
  displayName: string;
  family: string;
}
