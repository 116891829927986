import { Logos } from './../../models/logo.model';

export const ScannetLogos: Logos = {
  updated: new Date(2024, 4, 15),
  package: {
    downloadUrl: '/assets/download/logo/scannet_logopackage_15052024.zip',
    background: '/assets/images/brands/scannet/logo/package-background.svg',
    logo: '/assets/images/brands/scannet/logo.svg',
    content: ['RGB Logo (Web/Digital)', 'CMYK Logo (Print)'],
  },
  variantes: [
    {
      logo: '/assets/images/brands/scannet/logo.svg',
      backgroundColor: '#F5F6FA',
      title: 'Main logo',
      description: 'The main version should be used whenever possible.',
    },
    {
      logo: '/assets/images/brands/scannet/inverted-logo.svg',
      backgroundColor: '#30302e',
      title: 'Inverted logo',
      description:
        'The inverted version should be used on dark and busy backgrounds.',
    },
  ],
  spaceing: {
    images: [
      '/assets/images/brands/scannet/logo/spacing.svg',
    ],
    rules: [
      {
        title: 'Spacing',
        description: `To make sure the logo is not going to be placed too close to an edge,
        leave at least one of the letter "S" around the logo as space (safearea).`,
      },
    ],
  },
  minimumSizes: {
    web: { logo: '/assets/images/brands/scannet/logo/minimum-rgb.svg', minimumSize: '80px' },
    print: { logo: '/assets/images/brands/scannet/logo/minimum-cmyk.svg', minimumSize: '20mm' }
  },
  misuses: [
    {
      logo: '/assets/images/brands/scannet/logo/misuse/rotate.svg',
      note: `Don’t rotate the logo.`
    }, {
      logo: '/assets/images/brands/scannet/logo/misuse/stretch.svg',
      note: `Don’t stretch the logo.`
    }, {
      logo: '/assets/images/brands/scannet/logo/misuse/full-color.svg',
      note: `Don’t use a different full or gradient color of the entire logo other than white.`
    }, {
      logo: '/assets/images/brands/scannet/logo/misuse/background-color.svg',
      note: `Don’t use the main logo on similar or busy background colors.`,
      backgroundColor: '#484848'
    },
  ],
  some: {
    images: [
      '/assets/images/brands/scannet/logo/some/some-inverted-logo.svg'
    ],
    title: 'Social Media profile image',
    note: `For social medias a white version of the logo on Green Speed background should be used as a profile image.`
  },
  favIcon: {
    icon: '/assets/images/brands/scannet/logo/favicon.svg',
    note: `When the space is very tight or small, like a favicon, use the "S" in ScanNet as icon.`
  }
};
