import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { AuthService } from './../../services/auth.service';
import { LoginDialogComponent } from './../login-dialog/login-dialog.component';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIcon]
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() updated: Date;
  @Input() login: boolean;
  authorized: boolean;
  authorizedSubscription: Subscription = new Subscription();

  private authService = inject(AuthService);
  private dialog = inject(MatDialog);

  ngOnInit(): void {
    this.authorizedSubscription = this.authService.authorizedSubject.subscribe((data) => {
      this.authorized = data;
    });
  }

  onLogin(): void {
    this.dialog.open(LoginDialogComponent, {
      width: '410px'
    });
  }

  ngOnDestroy(): void {
    this.authorizedSubscription.unsubscribe();
  }

}
