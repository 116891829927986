import { Typography, FontFamily, FontLinkIcon } from './../../models/typography.model';

const nunito: FontFamily = {
  displayName: 'DM Sans',
  family: '"DM Sans", sans-serif'
};

export const SimplyTypography: Typography = {
  updated: new Date(2022, 9, 4),
  googleFonts: ['DM Sans:400,500,700'],
  fonts: [
    { fontFamily: nunito, defaultWeight: 400, fontLink: {
      icon: FontLinkIcon.Google,
      link: 'https://fonts.google.com/specimen/DM+Sans'
    }, fontWeight: [
      { name: 'Light', weight: 400 },
      { name: 'Regular', weight: 500 },
      { name: 'Bold', weight: 700 },
    ]}
  ],
  fontSizes: [
    { header: 'Headings', sizes: [
      { name: 'h1', fontFamily: nunito, fontSize: 80, fontWeight: 400 },
      { name: 'h2', fontFamily: nunito, fontSize: 70, fontWeight: 400 },
      { name: 'h3', fontFamily: nunito, fontSize: 64, fontWeight: 400 },
      { name: 'h4', fontFamily: nunito, fontSize: 46, fontWeight: 400 },
      { name: 'h5', fontFamily: nunito, fontSize: 36, fontWeight: 400 },
      { name: 'h6', fontFamily: nunito, fontSize: 26, fontWeight: 400 },
    ]},
    { header: 'Body text', sizes: [
      { name: 'Body', fontFamily: nunito, fontSize: 14, fontWeight: 400, lorem: true }
    ]}
  ]
};
