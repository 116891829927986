import { Personas } from '../../models/persona.model';

export const SimplyPersonas: Personas = {
  updated: new Date(2022, 10, 16),
  audience: {
    description: `Simply.com's brand audience can be divided into  two  main groups. Here we represent some  of their most important characteristics. One priority in everything we create and communicate is to balance those different profiles needs and objectives.`,
    areas: [
      {
        header: 'Ease-demanding',
        audiences: [
          'Individual',
          'Small company',
          'Layperson profile',
          'Price oriented',
        ],
      },
      {
        header: 'Tech-demanding',
        audiences: [
          'Ecommerce',
          'Big company',
          'Technical profile',
          'Specs oriented',
        ],
        colors: {
          background: '#0A1D2E',
          primary: '#D6FF7E',
          text: '#ffffff'
        }
      },
    ],
  },
};
