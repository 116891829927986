import { transition, trigger, useAnimation } from '@angular/animations';

import { fadeInAnimation, fadeOutAnimation } from './fade.animation';

export const FadeIn = trigger('fadeIn', [
  transition(':enter', [
    useAnimation(fadeInAnimation)
  ])
]);

export const Fade = trigger('fade', [
  transition(':enter', [
    useAnimation(fadeInAnimation)
  ]),
  transition(':leave', [
    useAnimation(fadeOutAnimation)
  ])
]);
