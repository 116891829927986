import { ToneOfVoice } from './../../models/tone-of-voice.model';

export const curanetToneOfVoices: ToneOfVoice = {
 updated: new Date(2021, 6, 8),
 keys: [
   { icon: `partnerskab.svg`, header: 'Partnerskab', description: 'Vi ser ikke bare en kunde, men en samarbejdspartner. Vi vil en fælles tilgang, bygget på gensidig tillid og support.' },
   { icon: `professionalisme.svg`, header: 'Professionalisme', description: 'Selvom vi er venskabelige, er vi professionelle. Vi har styr på produktet. Vi besidder ekspertviden.' },
   { icon: `familiaritet.svg`, header: 'Familiaritet', description: 'Vi vægter venskabelighed og oprigtighed. Vi er en virksomhed, hvor mennesket er i fokus. Vi lægger en ære i personlig support.' },
   { icon: `sikkerhed.svg`, header: 'Kompromisløs sikkerhed', description: 'Vi leverer sikkerhed i form af vores state-of-the-art datacenter. Underbygget af tillid, troværdighed, seriøsitet og viden. Sikkerhed døgnet rundt.' }
 ],
 goldenCircle: {
  what: 'Vi rådgiver og drifter større hostingløsninger',
  how: 'Det gør vi gennem en skalerbar og sikker IT-løsning',
  why: 'Vi vil gøre det muligt at vækste online gennem den rette IT-løsning'
 },
 whatDoWeDo: [
  { header: 'Stemme', description: `
      <p>Vores stemme i Curanet skal overordnet og konsistent udtrykke vores DNA; partnerskab, familiaritet, professionalisme og kompromisløs sikkerhed.</p>
      <p>Partnerskab og familiaritet skaber en stemme, der er uformel og familiær, fremfor formel og distancerende. I kombination med professionalisme og kompromisløs sikkerhed, som er mere formelle værdier, sikrer vi, at vores stemme forbliver troværdig og seriøs.</p>
      <p>Vi taler i øjenhøjde, uden et højtragende teknisk sprogniveau. Vi er familiære, og bruger personlige pronominer, såsom vi, du, os, vores, jeres osv.</p>
      <p>Det er vigtigt, at vi er konsistente i vores stemme. Det skaber genkendelse hos vores relationer og styrker vores familiaritet og brand.</p>
  `},
  { header: 'Tone', description: `
      <p>Vores tone er frisk, uformel, personlig og inviterende. Vi retter altid vores tone mod den laveste tekniske fællesnævner. Vi kan efterfølgende justere tonen ud fra teknisk niveau. Vi sørger for at budskaberne bliver forklaret, men vi taler aldrig ned til modtageren.</p>
      <p>Tonen varieres ud fra kontekst. Handler det om løsninger og sikkerhed er den mere ’to the point’ og professionel. Hvis det tekniske niveau er højt, kan vi være mere direkte. Er det lavere, er den mere tålmodig og inkluderende.</p>
      <p>Vi nævner ikke os selv blot som Curanet. Det skaber en upersonlig tone. Når vi nævner Curanet kombinerer vi det altid med et personligt pronomen, såsom ’os bag Curanet’, ’vi i Curanet’, ’vores løsninger’. Det understøtter vores stemme og DNA.</p>
  `}, ],
  doAndDonts: [
    {
      title: 'Generelt',
      header: 'Eksempler på, hvordan vores Tone of Voice generelt kommer i spil',
      doDonts: [
        {
          do: {
            description: `"Vi indgår i et samarbejde med hinanden. Vi leverer produkterne og i brander dem. I sælger dem videre som jeres egne."`,
            reason: `1. person, personligt`
          },
          dont: {
            description: `"Curanet sælger white-label produkter med henblik på reselling. Kunder kan brande produkterne selv, og sælge dem videre, som var det deres egne."`,
            reason: `3. Person, formelt`
          }
        }, {
          do: {
            description: `"Har du eller din virksomhed brug for hjælp, eller spørgsmål til vores løsninger, så tøv ikke med at kontakte os – vi er altid klar på en uforpligtende snak!"`,
            reason: `1. person, personligt`
          },
          dont: {
            description: `Hvis der er spørgsmål til vores produkter eller løsninger, så har Curanet et hold af supportere klar til tage imod spørgsmål og henvendelser.`,
            reason: `3. Person, formelt`
          }
        }
      ]
    }, {
      title: 'Website',
      header: 'Eksempler på vores website',
      description: `Tone of Voice på hjemmesiden afhænger af konteksten.<br>Specifikke produkt- og løsningssider skal være mere professionelle og formelle i tonen. Introducerende sider - "om os", "om konceptet" - må gerne være mere uformelle og personlige - det er vores historie, vi fortæller her.`,
      doDonts: [
        {
          do: {
            description: `
              <p>Det kan godt være, vi bruger et redundant netværk, men der er intet redundant ved vores løsninger.</p>
              <p>Vi går ikke på kompromis med kvalitet. Vi har et state-of-the-art datacenter, hvor din digitale sikkerhed er under konstant opsyn. Vi vil levere de bedste løsninger, så du kan levere de bedste løsninger til dine kunder...</p>
            `,
            reason: `I dette eksempel er vi seriøse og kundevendte.<br>Vi lægger vægt på partnerskab og vores troværdighed.`
          },
          dont: {
            description: `Curanet går rigtig meget op i sikkerhed! Du kan være sikker på, at dine data bliver passet godt på. Tjek vores produkter og løsninger - de er helt sikkert lige hvad du står og mangler! Det koster lidt mere, men du vil ikke blive skuffet.`,
            reason: `Overdrivelse fremmer forståelse.<br>Undlad udråbstegn, da det i denne kontekst kan signalere useriøsitet. Sælg produktet med ord, fremfor ved direkte at pådutte det den eventuelle kunde`
          }
        }, {
          do: {
            description: `Hos Curanet åbnede vi i 2002 dørene op for et unikt koncept, der endnu ikke havde slået igennem på det danske marked for hostede it-løsninger. Vores koncept bygger på, at...`,
            reason: `I dette eksempel er vi opmærksomme på at at være personlige i vores kommunikation, når vi omtaler Curanet.`
          },
          dont: {
            description: `Curanet blev stiftet i 2002. Virksomheden har siden da specialiseret sig i B2B salg af white-paper produkter til andre forhandlere. Det har været en stor success, og Curanet har i dag mere end 1000 forhandlere af sine produkter.`,
            reason: `I kontrast er dette eksempel upersonligt, og inviterer ikke kunden "ind I butikken".`
          }
        }
      ]
    }, {
      title: 'Sociale Medier',
      header: 'Eksempler på Tone of Voice på sociale medier.',
      description: 'Vores DNA skal skinne igennem i vores opslag - Vi skal gøre de sociale medier sociale.',
      doDonts: [
        {
          title: 'LinkedIn',
          do: {
            image: `some-1.png`,
            reason: `Sæt gerne ansigt på - invitér dem "indenfor i butikken". Udråbstegn må gerne bruges, da det signalerer friskhed. Undlad at bruge for mange emojis. Vi vil gerne fremstå personlige, men samtidig professionelle.`
          },
          dont: {
            image: 'some-2.png',
            reason: `I dette eksempel, er der brugt for mange emojis. Sammen med -køb-her-og-nu retorikken, skaber det et opslag, som ikke stemmer overens med vores DNA, og hvad vi ved om vores kunder. Derudover er teksten upersonlig.`
          }
        }
      ]
    }, {
      title: 'E-mails',
      header: 'Eksempler på, hvordan vores Tone of Voice kommer i spil i e-mails',
      doDonts: [
        {
          do: {
            image: 'mail-1.png',
            reason: `Anvend en personlig og imødekommende tone. Introducér gerne dig selv.  Tag udgangspunkt i, hvor kunden kommer fra rent teknisk - vær inkluderende og invitér til dialog.`
          },
          dont: {
            image: 'mail-2.png',
            reason: `Her er tonen formel og upersonlig. Den inviterer ikke til dialog. Tværtimod får man indtrykket af, at man helst vil undgå, at en kunde ringer ind.`
          }
        }
      ]
    }
  ],
};
