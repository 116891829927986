import { Typography, FontFamily, FontLinkIcon } from './../../models/typography.model';

const circular: FontFamily = {
  displayName: 'Circular',
  family: '"circular", sans-serif'
};

export const scannetTypography: Typography = {
  updated: new Date(2020, 3, 22),
  /*downloadUrl: '/assets/download/fonts/scannet_fontpack_22042020.zip',*/
  customFont: {
    families: ['circular'],
    urls: ['/assets/fonts/circular/circular.css']
  },
  fonts: [
    { fontFamily: circular, defaultWeight: 300, fontLink: {
      link: 'https://lineto.com/typefaces/circular',
      icon: FontLinkIcon.Custom
    }, fontWeight: [
      { name: 'Book Light', weight: 300 },
      { name: 'Medium', weight: 500 },
      { name: 'Bold', weight: 700 },
      { name: 'Black', weight: 900 },
    ]}
  ],
  fontSizes: [
    { header: 'Headings', sizes: [
      { name: 'h1', fontFamily: circular, fontSize: 72, fontWeight: 700 },
      { name: 'h2', fontFamily: circular, fontSize: 48, fontWeight: 700 },
      { name: 'h3', fontFamily: circular, fontSize: 28, fontWeight: 700 },
      { name: 'h4', fontFamily: circular, fontSize: 24, fontWeight: 700 },
      { name: 'h5', fontFamily: circular, fontSize: 18, fontWeight: 700 },
      { name: 'h6', fontFamily: circular, fontSize: 16, fontWeight: 300 },
    ]},
    { header: 'Body text', sizes: [
      { name: 'Body', fontFamily: circular, fontSize: 16, fontWeight: 300, lorem: true }
    ]}
  ]
};
