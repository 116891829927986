import { ToneOfVoice } from '../../models/tone-of-voice.model';

export const ScannetToneOfVoices: ToneOfVoice = {
 updated: new Date(2023, 2, 7),
 keys: [
   { icon: `passion.svg`, header: 'Passion', description: 'Vi er passionerede om at hjælpe vores kunder med en driftssikker IT-løsning. Hos ScanNet brænder vi for teknik. Kunderne kan trygt outsource dele eller hele deres IT-løsning til os. Dermed kan de i stedet koncentrere sig om deres kerneforretning, og det de brænder for.' },
   { icon: `counseling.svg`, header: 'Rådgivning', description: 'Vi tilbyder hurtig og kompetent rådgivning og adgang til teknisk sparring. Vi sikrer os, at kunderne føler sig hørt og mødt, når vi rådgiver dem i det bedste IT-setup til deres virksomheds behov.' },
   { icon: `trust.svg`, header: 'Pålidelighed', description: 'Kunderne kan stole på os. Vi holder det, vi lover. Vi er til rådighed, når de har behov for os. Det betyder, at vi er tilgængelige 365 dage om året og yder 24 timers support, hvis der opstår akutte IT-problemer.' },
   { icon: `relationship.svg`, header: 'Relationer ', description: 'Vi værner om vores relationer til kunderne. Når de indgår et samarbejde med os, så er de her ofte over en længere periode. Derfor har vi fokus på at skabe langvarige relationer, hvor kunderne har tillid til os. Vores kunder bliver altid mødt i øjenhøjde.' },
   { icon: `lock.svg`, header: 'Sikkerhed', description: 'Sikkerhed er altafgørende for kunderne. Det samme er det for os. Vi arbejder hele tiden på at udvikle vores systemer, så de lever op til højeste standarder, når det gælder sikkerhed og certificeringer. Vi sikrer en oppetid på over 99%. Vores datacentre er fysisk sikrede, og vi tilbyder tocenterdrift til kunderne, der sikrer backup af deres data.' }
 ],
 whatDoWeDo: [
  { header: 'Stemme', description: `
      <p>Vores stemme i ScanNet skal hele vejen igennem virksomheden udtrykke vores værdier passion, rådgivning, pålidelighed, relationer og sikkerhed. </p>
      <p>Vi vægter samarbejde og tilgængelighed højt, og vi står altid klar med teknisk sparring. Vi skaber en stemme, der er uformel og jordnær, fremfor formel og distanceret. Vi bruger personlige pronominer, såsom vi, du, os, vores, jeres osv.</p>
      <p>Vi er passionerede og bidrager med ekspertviden, der sikrer, at vores stemme forbliver pålidelig og seriøs. Vi er kvalitetsbevidste, og vi er her for at finde den helt rette IT-løsning til kunderne.</p>
      <p>Det er vigtigt, at vi er konsistente i vores stemme. Det skaber genkendelse hos vores kunder og styrker vores troværdighed som samarbejdspartner og brand.</p>
  `},
  { header: 'Tone', description: `
      <p>Vores tone er erfaren, frisk, personlig og inviterende. Vi taler altid i øjenhøjde, og justerer tonen ud fra kundernes tekniske niveau. Vi sørger for, at budskaberne bliver forklaret, så kunderne føler sig inddraget og trygge.</p>
      <p>Tonen varieres ud fra målgruppe, kontekst og platform. Hvis budskabet er informativt, vil tonen være mere direkte. Hvis budskabet har til hensigt at skabe engagement, er tonen mere frisk og inkluderende.</p>
  `}, ],
  doAndDonts: [
    {
      title: 'Website',
      header: 'Website',
      doDonts: [
        {
          title: 'Blogindlæg',
          do: {
            description: `
              <p>Hvorfor skal du sikre dig mod datatab med Microsoft 365 Backup? Det får du seks årsager til her. </p>
              <p>Forestil dig, at alt den data som du har opbygget over flere år, i takt med at virksomheden er vækstet, pludselig er væk. Det kan ske på blot ét forkert klik. Et forkert klik, der kan bremse samtlige arbejdsprocesser i virksomheden og i værste tilfælde sætte dybe spor i omsætningen.   </p>
            `,
            reason: `Hav fokus på, hvordan vi kan skabe værdi for kunderne gennem blogindlæg. Vække nysgerrighed eller interesse i starten af blogindlægget, så kunderne får lyst til at læse med. Skriv fængende med afsæt i kundernes behov og udfordringer `
          },
          dont: {
            description: `Man bør altid sikre sig mod datatab med Microsoft 365 Backup. Det skal man bl.a. fordi man kan blive ramt af hackerangreb, der kan forårsage datatab. Et hackerangreb kan f.eks. være et Denial of Service-angreb (DoS). Disse overbelaster systemet eller maskinen, som til sidst går ned, hvilket gør den utilgængelig for ejerne, mens hackerne kan få uforstyrret adgang. Det er blot en ud af flere grunde til, at man bør have Microsoft 365 Backup. Køb det her: `,
            reason: `Undgå lange og krínglede sætninger, at skrive upersonligt og distanceret. Altid ret fokus mod, hvilken betydning det har for kunderne. Undgå at bruge ”man”. Tal i stedet direkte til kunderne ved at bruge ”du”. Undgå at det bliver tør læsning.`
          }
        },
        {
          title: 'Om os',
          do: {
            description: `I ScanNet har vi 24 års erfaring med at skabe de bedste rammer for din IT-løsning. Vi vægter sikkerhed højt og arbejder hele tiden på at udvikle vores systemer, så de lever op til højeste standarder, når det gælder sikkerhed og certificeringer. Vi sikrer en oppetid på over 99%. Vores datacentre er fysisk sikrede, og vi tilbyder tocenterdrift, der sikrer backup af alt data. `,
            reason: `”Om os” siden skal give kunderne et indtryk af, at ScanNet er en god samarbejdspartner. Det gør vi ved at fortælle, hvem vi er, og hvordan det kommer til udtryk overfor kunderne f.eks. ved at skrive, at vi har en oppetid på over 99% `
          },
          dont: {
            description: `ScanNet blev grundlagt i 1999, og vi har 24 års erfaring bag os. Vi er eksperter i IT, og vi går meget op i sikkerhed. Vi er kvalitetsbevidste. Vi sælger serverhosting, CMS-systemer, ERP-integration, databaseløsninger samt IT-outsourcing.  `,
            reason: `Undgå at teksten er distanceret og upersonlig, samt kun at fortælle om virksomhedens egne egenskaber. Men også udfolde, hvilken værdi det skaber for kunderne  `
          }
        }
      ]
    }, {
      title: 'Sociale Medier',
      header: 'Sociale medier.',
      description: 'Vi skal gøre de sociale medier sociale. Det gør vi bl.a. med billeder og personlighed. Sæt gerne ansigt på og invitér dem ”indenfor i forretningen”. Vores værdier som passion, rådgivning, pålidelighed, relationer og sikkerhed skal skinne igennem på tværs af platforme. Det skaber en genkendelighed hos vores kunder og styrker vores troværdighed. Derimod, når det gælder vores tone, og det content vi deler, er det vigtigt, at vi formår at tilpasse kommunikationen til de forskellige sociale medier.',
      doDonts: [
        {
          title: 'LinkedIn',
          do: {
            image: `linkedin-1.png`,
            reason: `Tag læseren i hånden, og gør det letforståeligt. Tal gerne ind i udfordringer og behov som målgruppen kan relatere til. Brug mellemrumstasten og skab luft i teksten.`
          },
          dont: {
            image: 'linkedin-2.png',
            reason: `Undgå at være afsenderorienteret samt kun at fortælle om produkternes egenskaber. Men også udfolde, hvilken værdi det skaber for kunderne.`
          }
        },
        {
          title: 'Facebook',
          do: {
            image: `facebook-1.png`,
            reason: `Hav fokus på hvilken værdi kunderne får, gør det personligt og uformelt. Lad brandværdierne skinne igennem opslaget.`
          },
          dont: {
            image: 'facebook-2.png',
            reason: `Undgå at pådutte kunderne salg af produkter og løsninger. Undgå at teksten er distanceret og upersonlig.`
          }
        }
      ]
    }, {
      title: 'E-mails',
      header: 'Nyhedsmails',
      description: 'Hold nyhedsmails korte og præcise. Vores kunder bliver eksponeret for utallige mails og budskaber dagligt. Skriv ikke for teknisk omkring produkterne, men gør det letforståeligt for kunderne. Anvend en personlig og imødekommende tone. Tag udgangspunkt i, hvad formålet med nyhedsmailen er og hvilken relevans det har for kunderne. Hvilke behov kan kunderne få dækket? Hvilken betydning har det for kunderne? Gør det klart, at kunderne altid gerne må tage kontakt, og at vi er til rådighed med teknisk sparrring.',
      doDonts: [
        {
          do: {
            image: 'mail-1.png',
            reason: `Inviter til, at kunderne altid må tage kontakt. Vi står klar med teknisk sparring. Gør det kort og klart, da kunderne bombarderes med mails hver dag. Skab luft i mailen og Hav fokus på, hvilken værdi det skaber for kunderne.`
          },
          dont: {
            image: 'mail-2.png',
            reason: `Skriv ikke for teknisk, da det kan medføre, at du taber kunderne og heller ikke for lange og kringlede sætninger. Undgå at pådutte kunderne vores produkter, have mere fokus på produktets egenskaber fremfor hvilken værdi det reelt kan skabe for kunderne.`
          }
        }
      ]
    }
  ],
};
