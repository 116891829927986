import { Templates } from './../../models/template.model';

export const SimplyTemplates: Templates = {
  updated: new Date(2022, 2, 9),
  package: {
    content: ['Download the template you want to use'],
    downloadUrl: '...'
  },
  areas: [
    {
      name: 'Presentations',
      groups: [
        {
          name: 'Powerpoints',
          columns: 2,
          templates: [
            {
              name: 'Powerpoint - Presentation ',
              image: '/assets/images/brands/simply/templates/præsentation.jpg',
              downloadUrl: '/assets/download/templates/simply/præsentation.pptx'
            },
            {
              name: 'Powerpoint - Webinar ',
              image: '/assets/images/brands/simply/templates/webinar.jpg',
              downloadUrl: '/assets/download/templates/simply/webinar.pptx'
            },
            {
              name: 'Powerpoint - Month report',
              image: '/assets/images/brands/simply/templates/månedsrapport.jpg',
              downloadUrl: '/assets/download/templates/simply/månedsrapport.pptx'
            },
          ]
        }
      ]
    }, {
      name: 'Documents',
      groups: [
        {
          name: 'Word',
          columns: 3,
          templates: [
            {
              name: 'Generel Word document',
              image: '/assets/images/brands/simply/templates/word.jpg',
              downloadUrl: '/assets/download/templates/simply/dokument.docx'
            },
          ]
        }, /* {
          name: 'Indesign',
          columns: 3,
          templates: [
            {
              name: 'Generel dokument',
              image: '/assets/images/brands/simply/templates/word.jpg',
              downloadUrl: ''
            }
          ]
        }*/
      ]
    }
  ]
};
