import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import cssVars from 'css-vars-ponyfill';

import { Theme, THEMES } from '../models/theme.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ThemeService {

  theme: string;
  private themeWrapper = null;

  constructor(@Inject(PLATFORM_ID) private platformId, @Inject(THEMES) public themes: Theme[]) {
    if (isPlatformBrowser(this.platformId)) {
      this.themeWrapper = document.querySelector('body');
   }
  }

  getTheme(name: string) {
    const theme = this.themes.find(t => t.name === name);
    if (!theme) {
      throw new Error(`Theme not found: '${name}'`);
    }
    return theme;
  }

  setTheme(name: string) {
    this.theme = name;
  }

  updateLayoutWithTheme() {
    if (isPlatformBrowser(this.platformId)) {
      const theme = this.getTheme(this.theme);
      for (const t of this.themes) {
        this.themeWrapper.classList.remove(`${t.name}-theme`);
      }
      this.themeWrapper.classList.add(`${theme.name}-theme`);

      cssVars({
        variables: theme.properties,
        watch: true,
        preserveStatic: false,
        include: 'style',
      });
    }
  }

}
