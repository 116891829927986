import { Brand } from '../models/brand.model';
import { ScannetToneOfVoices } from './tone-of-voice/scannet.tone-of-voice';
import { scannetTypography } from './typography/scannet.typography';
import { ScannetColors } from './colors/scannet.colors';
import { ScannetLogos } from './logos/scannet.logos';
import { ScannetTemplates } from './templates/scannet.templates';

export const ScannetBrand: Brand = {
  name: 'scannet.dk',
  alias: 'scannet',
  displayName: 'ScanNet',
  colors: ScannetColors,
  logos: ScannetLogos,
  toneOfVoice: ScannetToneOfVoices,
  typography: scannetTypography,
  templates: ScannetTemplates,
};
