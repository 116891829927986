import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import sha256 from 'crypto-js/sha256';



@Injectable({
  providedIn: 'root'
})
export class AuthService { 
  private token = `rKqN&L*~DR)nnr"D.3}!msJ>#9eY;1u#$T[2Oz@'Ak&5QQ:n)OzTf7A3WstlN&y`;
  private password = "50ff26b26d175fd50197922d85909bdcdf20b8e81a9b9d873452daad5ad57e9a";  // https://emn178.github.io/online-tools/sha256.html
  private authorized = false;
  authorizedSubject = new BehaviorSubject<boolean>(false);

  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId) {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('token') === this.token) {
        this.authorizedSubject.next(true);
        this.authorized = true;
      }
    }
  }

  authorize(password: string) {
    if (password === this.password) {
      this.authorizedSubject.next(true);
      this.authorized = true;
      this.setLocalToken();
      return true;
    }
    return false;
  }

  logout() {
    localStorage.removeItem('token');
    this.authorizedSubject.next(false);
    this.router.navigate(['/']);
  }

  private setLocalToken() {
    localStorage.setItem('token', this.token);
  }

  isAuthorized() {
    return this.authorized;
  }

  hashString(str: string) {
    let hash = sha256(str).toString();
    return hash;
  }

}

