import { Typography, FontFamily, FontLinkIcon } from './../../models/typography.model';

const opensans: FontFamily = {
  displayName: 'Poppins',
  family: '"Poppins", sans-serif'
};

export const teamblueTypography: Typography = {
  updated: new Date(2023, 11, 18),
  downloadUrl: '/assets/download/fonts/teamblue_fontpack_18122023.zip', 
  googleFonts: ['Poppins:300,400,500,600,700'],
  fonts: [
    { fontFamily: opensans, defaultWeight: 400, fontLink: {
      icon: FontLinkIcon.Google,
      link: 'https://fonts.google.com/specimen/Poppins'
    }, fontWeight: [
      { name: 'Light', weight: 300 },
      { name: 'Regular', weight: 400 },
      { name: 'Medium', weight: 500 }
    ]}
  ],
  fontSizes: [
    { header: 'Headings', sizes: [
      { name: 'h1', fontFamily: opensans, fontSize: 44, fontWeight: 400 },
      { name: 'h2', fontFamily: opensans, fontSize: 30, fontWeight: 400 },
      { name: 'h3', fontFamily: opensans, fontSize: 26, fontWeight: 400 },
      { name: 'h4', fontFamily: opensans, fontSize: 22, fontWeight: 400 },
      { name: 'h5', fontFamily: opensans, fontSize: 18, fontWeight: 400 },
      { name: 'h6', fontFamily: opensans, fontSize: 15, fontWeight: 400 },
    ]},
    { header: 'Body text', sizes: [
      { name: 'Body', fontFamily: opensans, fontSize: 17, fontWeight: 400, lorem: true }
    ]}
  ]
};
