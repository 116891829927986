import { Brand } from '../models/brand.model';
import { teamblueTypography } from './typography/teamblue.typography';
import { TeamblueColors } from './colors/teamblue.colors';
import { TeamblueLogos } from './logos/teamblue.logos';

export const TeamBlueBrand: Brand = {
  name: 'team.blue',
  alias: 'teamblue',
  displayName: 'Team.blue',
  colors: TeamblueColors,
  logos: TeamblueLogos,
  typography: teamblueTypography,
};
