import { Logos } from './../../models/logo.model';

export const SimplyLogos: Logos = {
  updated: new Date(2020, 3, 22),
  package: {
    downloadUrl: '/assets/download/logo/simply_logopackage_21102022.zip',
    background: '/assets/images/brands/simply/logo/package-background.svg',
    logo: '/assets/images/brands/simply/logo.svg',
    content: ['RGB Logo (Web/Digital)', 'CMYK Logo (Print)'],
  },
  variantes: [
    {
      logo: '/assets/images/brands/simply/logo.svg',
      backgroundColor: '#F5F6FA',
      title: 'Main logo',
      description: 'The main version should be used whenever possible.',
    },
    {
      logo: '/assets/images/brands/simply/inverted-logo.svg',
      backgroundColor: '#0A1D2E',
      title: 'Inverted logo',
      description:
        'The inverted version should be used on dark and busy backgrounds.',
    },
  ],
  glyphs:[ {
    title: 'Alternative logos',
    description: 'The alternative versions should be used, when the logo wouldn’t work on busy backgrounds.',
    glyphs: [
      {
        logo: '/assets/images/brands/simply/logo/alternative-logo_dark.svg',
        backgroundColor: '#D6FF7E',
      },
      {
        logo: '/assets/images/brands/simply/logo/alternative-logo_white.svg',
        backgroundColor: '#B522C1',
      },
    ],
  },
  {
    title: 'Logomark',
    description: 'Only use this for situations where space is very tight, and where you will be unable to read the wordmark.',
    note: 'It is also allowed to be used as a big version of the logomark, if it is a faded background element.',
    glyphs: [
      {
        logo: '/assets/images/brands/simply/logo/glyph.svg',
        backgroundColor: '#F5F6FA',
      },
      {
        logo: '/assets/images/brands/simply/logo/inverted-glyph.svg',
        backgroundColor: '#0A1D2E',
      },
    ],
  }
],
  spaceing: {
    images: [
      '/assets/images/brands/simply/logo/spacing-1.svg',
      '/assets/images/brands/simply/logo/spacing-2.svg',
    ],
    rules: [
      {
        title: 'Spacing',
        description: `To make sure the logo is not going to be placed too close to an edge,
        leave at least one of the logomark elements around the logo as space (safearea).`,
      },
    ],
  },
  minimumSizes: {
    web: { logo: '/assets/images/brands/simply/logo/minimum-rgb.svg', minimumSize: '100px' },
    print: { logo: '/assets/images/brands/simply/logo/minimum-cmyk.svg', minimumSize: '25mm' }
  },
  rgbVsCmyk: { cmykLogo: '/assets/images/brands/simply/logo/logo-cmyk.svg', rgbLogo: '/assets/images/brands/simply/logo/logo-rgb.svg' },
  misuses: [
    {
      logo: '/assets/images/brands/simply/logo/misuse/rotate.svg',
      note: `Don’t rotate the logo.`
    }, {
      logo: '/assets/images/brands/simply/logo/misuse/stretch.svg',
      note: `Don’t stretch the logo.`
    }, {
      logo: '/assets/images/brands/simply/logo/misuse/full-color.svg',
      note: `Don’t use a different full or gradient color of the entire logo other than white/dark.`
    }, {
      logo: '/assets/images/brands/simply/logo/misuse/background-color.svg',
      note: `Don’t use the main logo on similar or busy background colors.`,
      backgroundColor: '#B522C1'
    }, {
      logo: '/assets/images/brands/simply/logo/misuse/fade.svg',
      note: `Don't fade the logo.`
    }, {
      logo: '/assets/images/brands/simply/logo/misuse/stacked.svg',
      note: `Don’t use a stacked version of the logo.`
    }, {
      logo: '/assets/images/brands/simply/logo/misuse/without-glyph.svg',
      note: `Don’t use the logo without the logomark.`
    }, {
      logo: '/assets/images/brands/simply/logo/misuse/change-logo.svg',
      note: `Don’t change in the logo overall color`
    }, {
      logo: '/assets/images/brands/simply/logo/misuse/lowdefinition-logo.png',
      note: `Don’t use low definition usage`
    }, {
      logo: '/assets/images/brands/simply/logo/misuse/outlined-logo.svg',
      note: `Don’t outline the logo`
    }
  ],
  some: {
    images: [
      '/assets/images/brands/simply/logo/some/some-glyph.svg'
    ],
    title: 'Social Media profile image',
    note: `For social medias the main logomark on white background can be used as a profile image.`
  },
  favIcon: {
    icon: '/assets/images/brands/simply/logo/favicon.svg',
    note: `Use the logomark for the favicon.`
  }
};
