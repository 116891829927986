import { Templates } from '../../models/template.model';

export const DandomainTemplates: Templates = {
  updated: new Date(2022, 2, 31),
  package: {
    content: ['Download the template you want to use'],
    downloadUrl: '...'
  },
  areas: [
    {
      name: 'Presentations',
      groups: [
        {
          name: 'Powerpoints',
          columns: 2,
          templates: [
            {
              name: 'Powerpoint - Webinar template ',
              image: '/assets/images/brands/dandomain/templates/webinar.png',
              downloadUrl: '/assets/download/templates/dandomain/webinar_template.pptx'
            }
          ]
        }
      ]
    }
  ]
};
