import { Component, OnInit, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { AuthService } from './../../services/auth.service';
import { SharedModule } from '../../shared.module';

@Component({
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  standalone: true,
  imports: [SharedModule]
})
export class LoginDialogComponent implements OnInit {
  form: UntypedFormGroup;
  wrongPassword = '';

  private authService = inject(AuthService);
  private dialogRef = inject(MatDialogRef<LoginDialogComponent>);

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      password: new UntypedFormControl(null, Validators.required)
    });
  }

  onLogin(): void {
    if (this.form.valid) {
      const pw = this.form.get('password').value;
      const auth = this.authService.authorize(this.authService.hashString(pw));
      if (auth) {
        this.dialogRef.close();
      } else {
        this.wrongPassword = pw;
      }
    }
  }

}
