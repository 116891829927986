import { Logos } from './../../models/logo.model';

export const DandomainLogos: Logos = {
  updated: new Date(2024, 4, 15),
  package: {
    downloadUrl: '/assets/download/logo/dandomain_logopackage_15052024.zip',
    background: '/assets/images/brands/dandomain/logo/package-background.svg',
    logo: '/assets/images/brands/dandomain/logo.svg',
    content: ['RGB Logo (Web/Digital)', 'CMYK Logo (Print)'],
  },
  variantes: [
    {
      logo: '/assets/images/brands/dandomain/logo.svg',
      backgroundColor: '#F5F6FA',
      title: 'Main logo',
      description: 'The main version should be used whenever possible.',
    },
    {
      logo: '/assets/images/brands/dandomain/inverted-logo.svg',
      backgroundColor: '#E6723D',
      title: 'Inverted logo',
      description:
        'The inverted version should be used on dark and busy backgrounds.',
    },
  ],
  spaceing: {
    images: ['/assets/images/brands/dandomain/logo/spacing.svg'],
    rules: [
      {
        title: 'Spacing',
        description: `To make sure the logo is not going to be placed too close to an edge,
        leave at least one of the letter "d" around the logo as space (safearea). There should be an 11px spacing between the logo and the 'partof' element.`,
      },
    ],
  },
  minimumSizes: {
    web: {
      logo: '/assets/images/brands/dandomain/logo/minimum-rgb.svg',
      minimumSize: '100px',
    },
    print: {
      logo: '/assets/images/brands/dandomain/logo/minimum-cmyk.svg',
      minimumSize: '25mm',
    },
  },
  rgbVsCmyk: {
    rgbLogo: '/assets/images/brands/dandomain/logo/logo-rgb.svg',
    cmykLogo: '/assets/images/brands/dandomain/logo/logo-cmyk.svg',
  },
  misuses: [
    {
      logo: '/assets/images/brands/dandomain/logo/misuse/rotate.svg',
      note: `Don’t rotate the logo.`,
    },
    {
      logo: '/assets/images/brands/dandomain/logo/misuse/stretch.svg',
      note: `Don’t stretch the logo.`,
    },
    {
      logo: '/assets/images/brands/dandomain/logo/misuse/full-color.svg',
      note: `Don’t use a different full or gradient color of the entire logo other than white.`,
    },
    {
      logo: '/assets/images/brands/dandomain/logo/misuse/background-color.svg',
      note: `Don’t use the main logo on similar or busy background colors.`,
      backgroundColor: '#FF8431',
    },
  ],
  some: {
    images: [
      '/assets/images/brands/dandomain/logo/some/some-logo.svg',
      '/assets/images/brands/dandomain/logo/some/some-inverted-logo.svg',
    ],
    title: 'Social Media profile image',
    note: `The main version of the logo on white background is available as a social media image, however the inverted logo with the orange background can be great to attract attention on the feed on some platforms.`,
  },
  favIcon: {
    icon: '/assets/images/brands/dandomain/logo/favicon.svg',
    note: `When the space is very tight or small, like a favicon, use the "d" in Dandomain as icon.`,
  },
};
