import { Templates } from '../../models/template.model';

export const ScannetTemplates: Templates = {
  updated: new Date(2022, 2, 9),
  package: {
    content: ['Download the template you want to use'],
    downloadUrl: '...'
  },
  areas: [
    {
      name: 'Presentations',
      groups: [
        {
          name: 'Powerpoints',
          columns: 2,
          templates: [
            {
              name: 'Powerpoint - Sales Presentation ',
              image: '/assets/images/brands/scannet/templates/scannet-præsentation.jpg',
              downloadUrl: 'https://teambluegroup.sharepoint.com/:p:/s/teamblueDenmark-Marketing/EXZhM8ywsHdHpp47remeoAYBongwVfSMxiBG-MhPWqRJhw?e=f0LHog',
              externalUrl: true
            },
          ]
        }
      ]
    }
  ]
};
