import { Logos } from './../../models/logo.model';

export const TeamblueLogos: Logos = {
  updated: new Date(2023, 11, 18),
  package: {
    downloadUrl: '/assets/download/logo/teamblue_logopackage_18122023.zip', 
    background: '/assets/images/brands/teamblue/logo/package-background.svg',
    logo: '/assets/images/brands/teamblue/logo.svg',
    content: ['RGB Logo (Web/Digital)', 'CMYK Logo (Print)', 'Part of team.blue - Logo (Web & Print)'],
  },
  variantes: [
    {
      logo: '/assets/images/brands/teamblue/logo.svg',
      backgroundColor: '#F5F6FA',
      title: 'Main logo',
      description: 'The main version should be used whenever possible.',
    },
    {
      logo: '/assets/images/brands/teamblue/inverted-logo.svg',
      backgroundColor: '#2B2B2B',
      title: 'Inverted logo',
      description:
        'The inverted version should be used on dark and busy backgrounds.',
    },
    {
      logo: '/assets/images/brands/teamblue/logo/alternative-inverted-logo.svg',
      backgroundColor: '#1C3F6D',
      title: 'Alternative inverted logo',
      description:
        'The alternative inverted version should be used on dark colored backgrounds.',
    },
  ],
  glyphs:[ {
    title: 'Part of team.blue',
    description: 'Download the logo package, where you will find the "Part of team.blue" logo in various variants. Incorporate the logo into our different brands to emphasize our affiliation with the team.blue network.',
    glyphs: [
      {
        logo: '/assets/images/brands/teamblue/logo/part-of-teamblue.svg',
        backgroundColor: '#F5F6FA',
      },
    ],
  }
],
  spaceing: {
    images: [
      '/assets/images/brands/teamblue/logo/spacing.svg',
    ],
    rules: [
      {
        title: 'Spacing',
        description: `To make sure the logo is not going to be placed too close to an edge,
        leave at least one of "t.b" around the logo as space (safearea).`,
      },
    ],
  },
  minimumSizes: {
    web: { logo: '/assets/images/brands/teamblue/logo/minimum-rgb.svg', minimumSize: '100px' },
    print: { logo: '/assets/images/brands/teamblue/logo/minimum-cmyk.svg', minimumSize: '25mm' }
  },
  rgbVsCmyk: { rgbLogo: '/assets/images/brands/teamblue/logo/logo-rgb.svg', cmykLogo: '/assets/images/brands/teamblue/logo/logo-cmyk.svg' },
  misuses: [
    {
      logo: '/assets/images/brands/teamblue/logo/misuse/rotate.svg',
      note: `Don’t rotate the logo.`
    }, {
      logo: '/assets/images/brands/teamblue/logo/misuse/stretch.svg',
      note: `Don’t stretch the logo.`
    }, {
      logo: '/assets/images/brands/teamblue/logo/misuse/full-color.svg',
      note: `Don’t use a different full or gradient color of the entire logo other than white or black`
    }, {
      logo: '/assets/images/brands/teamblue/logo/misuse/background-color.svg',
      note: `Don’t use the main logo on similar or busy background colors.`,
      backgroundColor: '#2B2B2B'
    }, {
      logo: '/assets/images/brands/teamblue/logo/misuse/fade.svg',
      note: `Don't fade the logo.`
    }
  ],
  some: {
    images: [
      '/assets/images/brands/teamblue/logo/some/some-logo.svg'
    ],
    title: 'Social Media profile image',
    note: `For social medias a special stacked version of the logo can be used as a profile image. Please note that this version of the logo is only allowed in this situation.`
  },
  favIcon: {
    icon: '/assets/images/brands/teamblue/logo/favicon.svg',
    note: `Use the light blue dot from the logo for the favicon.`
  }
};
