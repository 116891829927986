import { CmykColor, ColorGroup, Colors, PredefinedTexts, RgbColor, ColorTag  } from './../../models/color.model';

const primary: ColorGroup = {
  name: 'Main Colors',
  colors: [
    {
      name: 'T1 Dark Blue',
      color: {
        hex: '#213c6e',
        rgb: new RgbColor(33, 60, 110),
        cmyk: new CmykColor(70, 45, 0, 57)
      },
      tags: [
        ColorTag.Heading, ColorTag.Paragraph, ColorTag.Cta, ColorTag.Background, ColorTag.Illustration
      ]
    },
    {
      name: 'T2 White',
      color: {
        hex: '#ffffff',
        rgb: new RgbColor(255, 255, 255),
        cmyk: new CmykColor(0, 0, 0, 0),
        invertedText: true
      },
      tags: [
        ColorTag.Heading, ColorTag.Paragraph, ColorTag.Background, ColorTag.Illustration
      ]
    },
    {
      name: 'T3 Grey',
      color: {
        hex: '#f0f0f0',
        rgb: new RgbColor(255, 255, 255),
        invertedText: true
      }
    }
  ]
};

const secondary: ColorGroup = {
  name: 'Support Colors',
  text: {
    predefinedText: PredefinedTexts.Secondary
  },
  colors: [
    {
      name: 'T4 Light Blue',
      color: {
        hex: '#abd0e8',
        rgb: new RgbColor(171, 208, 232)
      },
      tags: [
        ColorTag.Background, ColorTag.Icon, ColorTag.Illustration
      ]
    },
    {
      name: 'T5 Pink',
      color: {
        hex: '#ff6978',
        rgb: new RgbColor(255, 105, 120)
      },
      tags: [
        ColorTag.Cta
      ],
      variants: [
        { hex: '#e75f6c', rgb: new RgbColor(231, 95, 108) },
      ]
    },
    {
      name: 'T6 Dark Grey',
      color: {
        hex: '#747575',
        rgb: new RgbColor(116, 117, 117)
      },
      tags: [
        ColorTag.Cta
      ]
    }
  ]
};

const gradients: ColorGroup = {
  name: 'Gradients',
  colors: [
  ]
};

const bw: ColorGroup = {
  name: 'Accent Colors',
  text: {
    predefinedText: PredefinedTexts.Accent
  },
  colors: [
    {
      name: 'T7 Blue',
      color: {
        hex: '#187dc1',
        rgb: new RgbColor(24, 125, 193)
      },
      tags: [
        ColorTag.ImportantInformation
      ]
    },
    {
      name: 'T8 Red',
      color: {
        hex: '#c11829',
        rgb: new RgbColor(193, 24, 41),
      }, 
      tags: [
        ColorTag.Error
      ]
    },
    {
      name: 'T9 Green',
      color: {
        hex: '#28a745',
        rgb: new RgbColor(40, 167, 69)
      },
      tags: [
        ColorTag.Success
      ]
    }
  ]
};

export const TeamblueColors: Colors = {
  updated: new Date(2023, 11, 18),
  package: {
    downloadUrl: '/assets/download/color/teamblue_colorstyleguide_18122023.pdf.zip', 
    content: [
      'Team.blue Style guide'
    ],
  },
  groups: [primary, secondary, gradients, bw],
  cmykExample: { hex: '#213c6e', cmykAsHex: '#203969' }
};
