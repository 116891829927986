import { CmykColor, ColorGroup, Colors, PredefinedTexts, RgbColor, ColorTag } from './../../models/color.model';

const primary: ColorGroup = {
  name: 'Primary Colors',
  text: {
    predefinedText: PredefinedTexts.Primary
  },
  colors: [
    {
      name: 'S1 Dark Navy',
      color: {
        hex: '#0A1D2E',
        rgb: new RgbColor(10, 29, 46),
        cmyk: new CmykColor(99, 74, 31, 84)
      },
      tags: [
        ColorTag.Background, ColorTag.Heading, ColorTag.Illustration, ColorTag.PhotoFilter, ColorTag.Logo, ColorTag.Cta, ColorTag.Icon
      ]
    },
    {
      name: 'S2 Pink Purle',
      color: {
        hex: '#B522C1',
        rgb: new RgbColor(181, 34, 193),
        cmyk: new CmykColor(49, 87, 0, 0)
      },
      tags: [
        ColorTag.Logo, ColorTag.Illustration, ColorTag.Link, ColorTag.Cta, ColorTag.Icon, ColorTag.PhotoFilter
      ]
    },
    {
      name: 'S3 Cloud Blue',
      color: {
        hex: '#D4DCF1',
        rgb: new RgbColor(212, 220, 241),
        cmyk: new CmykColor(39, 16, 0, 0),
        invertedText: true
      },
      tags: [
        ColorTag.Icon, ColorTag.Background, ColorTag.Illustration
      ]
    },
    {
      name: 'S4 Power Green',
      color: {
        hex: '#D6FF7E',
        rgb: new RgbColor(214, 255, 126),
        cmyk: new CmykColor(28, 0, 61, 0),
        invertedText: true
      },
      tags: [
        ColorTag.Icon, ColorTag.Illustration, ColorTag.Link, ColorTag.Cta, ColorTag.PhotoFilter
      ]
    }
  ]
};

const secondary: ColorGroup = {
  name: 'Secondary Colors',
  text: {
    predefinedText: PredefinedTexts.Secondary
  },
  colors: [
    {
      name: 'S5 Light Navy',
      color: {
        hex: '#122B3F',
        rgb: new RgbColor(18, 43, 63),
        cmyk: new CmykColor(73, 45, 24, 66)
      },
      tags: [
        ColorTag.Icon, ColorTag.Background, ColorTag.Illustration 
      ]
    },
    {
      name: 'S6 Light Blue',
      color: {
        hex: '#E7EBF6',
        rgb: new RgbColor(231, 235, 246),
        cmyk: new CmykColor(25, 11, 3, 0),
        invertedText: true
      },
      tags: [
        ColorTag.Icon, ColorTag.Background, ColorTag.Illustration
      ]
    },
    {
      name: 'S7 Bright Blue',
      color: {
        hex: '#EFF2F8',
        rgb: new RgbColor(239, 242, 248),
        cmyk: new CmykColor(6, 8, 6, 0),
        invertedText: true
      },
      tags: [
        ColorTag.Icon, ColorTag.Background, ColorTag.Illustration
      ]
    }
  ]
};

const gradients: ColorGroup = {
  name: 'Gradients',
  text: {
    predefinedText: PredefinedTexts.Gradients
  },
  colors: [
    {
      name: 'S301 Simply Blue Gradient',
      color: {
        hex: '#D4DCF1',
        rgb: new RgbColor(212, 220, 241),
        cmyk: new CmykColor(39, 16, 0, 0),
        invertedText: true
      },
      toColor: {
        hex: '#ffffff',
        rgb: new RgbColor(255, 255, 255),
        cmyk: new CmykColor(0, 0, 0, 0)
      },
      tags: [
        ColorTag.Background
      ]
    }
  ]
};

const bw: ColorGroup = {
  name: 'Greyscale',
  text: {
    predefinedText: PredefinedTexts.BlackAndWhite
  },
  colors: [
    {
      name: 'S8 Tech Grey',
      color: {
        hex: '#EBEBEB',
        rgb: new RgbColor(235, 235, 235),
        cmyk: new CmykColor(18, 13, 15, 0),
        invertedText: true,
      },
      tags: [
        ColorTag.Background, ColorTag.Illustration
      ]
    },
    {
      name: 'S9 Easy White',
      color: {
        hex: '#FFFFFF',
        rgb: new RgbColor(255, 255, 255),
        cmyk: new CmykColor(0, 0, 0, 0),
        invertedText: true,
      },
      tags: [
        ColorTag.Background, ColorTag.Heading, ColorTag.Icon
      ]
    }
  ]
};

export const SimplyColors: Colors = {
  updated: new Date(2022, 9, 4),
  package: {
    content: [
      'Adobe Swatch Exchange file (.ase)',
      'Adobe Photoshop Color file (.aco)'
    ]
  },
  groups: [primary, secondary, gradients, bw],
  cmykExample: { hex: '#B522C1', cmykAsHex: '#973B8E' }
};
