import { CmykColor, ColorGroup, Colors, PredefinedTexts, RgbColor, ColorTag } from './../../models/color.model';

const primary: ColorGroup = {
  name: 'Primary Colors',
  text: {
    predefinedText: PredefinedTexts.Primary
  },
  colors: [
    {
      name: 'S1 Green Speed',
      color: {
        hex: '#4CD981',
        rgb: new RgbColor(76, 217, 129),
        cmyk: new CmykColor(61, 0, 70, 0)
      },
      variants: [
        { hex: '#205734', rgb: new RgbColor(32, 87, 52) },
        { hex: '#2F834D', rgb: new RgbColor(47, 131, 77) },
        { hex: '#3CAE67', rgb: new RgbColor(60, 174, 103) },
        { hex: '#77E19B', rgb: new RgbColor(119, 225, 155) },
        { hex: '#9CE9B4', rgb: new RgbColor(156, 233, 180) },
        { hex: '#BEF0CD', rgb: new RgbColor(190, 240, 205), invertedText: true },
      ],
      tags: [
        ColorTag.Background, ColorTag.Cta, ColorTag.Icon, ColorTag.Link, ColorTag.Illustration
      ]
    },
    {
      name: 'S2 Electric blue',
      color: {
        hex: '#4CD9CA',
        rgb: new RgbColor(76, 217, 202),
        cmyk: new CmykColor(49, 0, 26, 0)
      },
      variants: [
        { hex: '#205751', rgb: new RgbColor(32, 87, 81) },
        { hex: '#2E8279', rgb: new RgbColor(46, 130, 121) },
        { hex: '#3EAEA2', rgb: new RgbColor(62, 174, 162) },
        { hex: '#78E1D5', rgb: new RgbColor(120, 225, 213) },
        { hex: '#9CE8DF', rgb: new RgbColor(156, 232, 223) },
        { hex: '#BEF1EA', rgb: new RgbColor(190, 241, 234), invertedText: true },
      ],
      tags: [
        ColorTag.Illustration
      ]
    }
  ]
};

const secondary: ColorGroup = {
  name: 'Secondary Colors',
  text: {
    predefinedText: PredefinedTexts.Secondary
  },
  colors: [
    {
      name: 'S3 Neon Lime',
      color: {
        hex: '#8ADD83',
        rgb: new RgbColor(138, 221, 131),
        cmyk: new CmykColor(39, 0, 67, 0)
      },
      tags: [
        ColorTag.Background, ColorTag.Illustration
      ]
    },
    {
      name: 'S4 Power Orange',
      color: {
        hex: '#ef6c0f',
        rgb: new RgbColor(239, 108, 15)
      },
      tags: [
        ColorTag.Cta, ColorTag.Illustration
      ]
    },
    {
      name: 'S5 Neutral Beige',
      color: {
        hex: '#E1DCD1',
        rgb: new RgbColor(225, 220, 209),
        cmyk: new CmykColor(0, 2, 7, 12),
        invertedText: true
      },
      tags: [
        ColorTag.Illustration
      ]
    },
    {
      name: 'S6 Dark Blue',
      color: {
        hex: '#030C2B',
        rgb: new RgbColor(3, 12, 43),
      },
      tags: [
        ColorTag.Background, ColorTag.Illustration
      ]
    }
  ]
};

const gradients: ColorGroup = {
  name: 'Gradients',
  text: {
    predefinedText: PredefinedTexts.Gradients
  },
  colors: [
    {
      name: 'S101 Neon Speed',
      color: {
        hex: '#4CD981',
        rgb: new RgbColor(76, 217, 129),
        cmyk: new CmykColor(61, 0, 70, 0)
      },
      toColor: {
        hex: '#8ADD83',
        rgb: new RgbColor(138, 221, 131),
        cmyk: new CmykColor(39, 0, 67, 0)
      },
      tags: [
        ColorTag.Background, ColorTag.Illustration, ColorTag.Icon, ColorTag.Cta
      ]
    },
    {
      name: 'S201 Electric Speed',
      color: {
        hex: '#4CD981',
        rgb: new RgbColor(76, 217, 129),
        cmyk: new CmykColor(61, 0, 70, 0)
      },
      toColor: {
        hex: '#4CD9CA',
        rgb: new RgbColor(76, 217, 202),
        cmyk: new CmykColor(49, 0, 26, 0)
      },
      tags: [
        ColorTag.Background, ColorTag.Heading, ColorTag.Illustration
      ]
    }
  ]
};

const bw: ColorGroup = {
  name: 'Greyscale',
  text: {
    predefinedText: PredefinedTexts.BlackAndWhite
  },
  colors: [
    {
      name: 'S6 Jet Black',
      color: {
        hex: '#30302e',
        rgb: new RgbColor(48, 48, 46),
        cmyk: new CmykColor(69, 63, 62, 57)
      },
      tags: [
        ColorTag.Heading, ColorTag.Background, ColorTag.Paragraph, ColorTag.Logo
      ]
    },
    {
      name: 'S7 Dark Stone',
      color: {
        hex: '#484848',
        rgb: new RgbColor(72, 72, 72),
        cmyk: new CmykColor(0, 0, 0, 89)
      },
      tags: [
        ColorTag.Background, ColorTag.Illustration
      ]
    },
    {
      name: 'S8 Steel Grey',
      color: {
        hex: '#868683',
        rgb: new RgbColor(134, 134, 131),
        cmyk: new CmykColor(51, 43, 43, 7)
      },
      tags: [
        ColorTag.Icon, ColorTag.Paragraph, ColorTag.Illustration
      ]
    },
    {
      name: 'S9 Cloudy Light',
      color: {
        hex: '#F2F2F2',
        rgb: new RgbColor(242, 242, 242),
        cmyk: new CmykColor(0, 0, 0, 5),
        invertedText: true
      },
      tags: [
        ColorTag.Heading, ColorTag.Background, ColorTag.Paragraph
      ]
    }
  ]
};

export const ScannetColors: Colors = {
  updated: new Date(2020, 3, 22),
  package: {
    downloadUrl: '/assets/download/color/scannet_colorpackage_22042020.zip',
    content: [
      'Adobe Swatch Exchange file (.ase)',
      'Adobe Photoshop Color file (.aco)'
    ],
  },
  groups: [primary, secondary, gradients, bw],
  cmykExample: { hex: '#4CD981', cmykAsHex: '#6eba70' }
};
